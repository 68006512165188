import { LeftOutlined } from '@ant-design/icons'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'

import CreateProductTypeSelector from '@/components/CreateProductTypeSelector/CreateProductTypeSelector'
import CreateShowModal from '@/components/CreateShowModal/CreateShowModal'
import { handleVideoUploadTranscodingSubscriptionData } from '@/components/CreateShowModal/VideoUploader/videoUploader.helper'
import ErrorPage404 from '@/components/Error/ErrorPage404/ErrorPage404'
import { FraudFightingBanner } from '@/components/FraudFightingBanner/FraudFightingBanner'
import { useGetCountProductsForSellerLazyQuery } from '@/components/ProductList/operations.generated'
import ProductList, { productTypeToTabName } from '@/components/ProductList/ProductList'
import { TabName } from '@/components/ProductList/types'
import { ShopsBanner } from '@/components/ShopsBanner/ShopsBanner'
import { StripeAccountIssueBanner } from '@/components/Stripe/StripeAccountIssueBanner/StripeAccountIssueBanner'
import StripeAutoDebitAgreementBanner from '@/components/Stripe/StripeAutoDebitAgreement/StripeAutoDebitAgreementBanner/StripeAutoDebitAgreementBanner'
import Button from '@/components/ui/Button/Button'
import Card from '@/components/ui/Card/Card'
import { notificationDanger, notificationSuccess, notificationWarning } from '@/components/ui/Notification/Notification'
import { TabBar } from '@/components/ui/TabBar/TabBar'
import ViewContainer from '@/components/ViewContainer/ViewContainer'
import { WarningInShowBanner } from '@/components/WarningInShowBanner/WarningInShowBanner'
import Config from '@/config/config'
import { ShowProvider, useShow } from '@/contexts/show/Show.context'
import { useUser } from '@/contexts/user/User.context'
import { useDocumentTitle } from '@/helpers/setDocumentTitle'
import { logger, LOGGER_LEVEL } from '@/network/common/logger'
import {
  ShowAuctionStatus,
  ShowFlashSaleStatus,
  ShowGiveawayStatus,
  ShowRaidStatus,
  ProductType,
} from '@/network/graphql/types.generated'
import { trackEvent } from '@/util/eventTracker'
import { trackError } from '@/util/sentry'
import ApplyForShowEvent from '@/views/Show/components/ApplyForShowEvent/ApplyForShowEvent'
import ChatBoxProvider from '@/views/Show/components/ChatBox/ChatBoxProvider'
import { Chat } from '@/views/Show/components/ChatBox/components/Chat/Chat'
import { ChatCommandsAndInfos } from '@/views/Show/components/ChatBox/components/ChatCommandsAndInfos/ChatCommandsAndInfos'
import { ShowAllBannedViewersList } from '@/views/Show/components/ChatBox/components/ShowBannedViewersList/ShowBannedViewersList'
import { ShowViewersList } from '@/views/Show/components/ChatBox/components/ShowViewersList/ShowViewersList'
import CurrentAuction from '@/views/Show/components/CurrentAuction/CurrentAuction'
import CurrentFlashSale from '@/views/Show/components/CurrentFlashSale/CurrentFlashSale'
import CurrentGiveaway from '@/views/Show/components/CurrentGiveaway/CurrentGiveaway'
import CurrentRaid from '@/views/Show/components/CurrentRaid/CurrentRaid'
import ExportOrdersButton from '@/views/Show/components/ExportOrdersButton/ExportOrdersButton'
import IssueReporter from '@/views/Show/components/IssueReporter/IssueReporter'
import NextItemsOptions from '@/views/Show/components/NextItemsOptions/NextItemsOptions'
import QuickStart from '@/views/Show/components/QuickStart/QuickStart'
import { RateShow } from '@/views/Show/components/RateShow/RateShow'
import SendShowNotification from '@/views/Show/components/SendShowNotification/SendShowNotification'
import ShowInfos from '@/views/Show/components/ShowInfos/ShowInfos'
import ShowSalesFigures from '@/views/Show/components/ShowSalesFigures/ShowSalesFigures'
import {
  useGetShowByIdQuery,
  CurrentGiveawayParticipantsDocument,
  useCurrentAuctionShowQuery,
  useCurrentGiveawayShowQuery,
  useCurrentShowBroadcastEndedSubscription,
  useCurrentShowBroadcastStartedSubscription,
  useCurrentShowBroadcastStatusQuery,
  useOnPreShowTeaserVideoTranscodingStatusChangedSubscription,
  useOnShowCurrentAuctionBidPlacedSubscription,
  useOnShowCurrentAuctionOrderAddedSubscription,
  useOnShowCurrentAuctionStatusChangedSubscription,
  useStartAuctionMutation,
  useStartGiveawayInShowMutation,
  type ShowByIdFragment,
  useCurrentRaidShowQuery,
  useOnShowCurrentRaidStatusChangedSubscription,
  useOnShowCurrentRaidUserParticipationChangedSubscription,
  useStartFlashSaleInShowMutation,
  useCurrentFlashSaleQuery,
  useOnShowFlashSaleUpdateSubscription,
  useShowCurrentGiveawayUpdateEndSubscription,
} from '@/views/Show/operations.generated'

import ApplyForShowEventModal from './components/ApplyForShowEvent/ApplyForShowEventModal'
import { ChatFontSize } from './components/ChatBox/components/Chat/ChatFontSize/ChatFontSize'

import type { ExtraProductFormOptions } from '@/components/CreateOrEditProduct2/types'
import type { Product, ShowGiveawayAudience } from '@/network/graphql/types.generated'
import type { Dayjs } from 'dayjs'

import './Show.scss'

const { VITE_APP_CLIENT_MONITORING_URL, VITE_APP_SHORT_TIMEOUT } = Config

dayjs.extend(utc)

const MAX_EXPECTED_SHOW_DURATION_IN_HOURS = 4

type ShowProps = {
  isShop?: boolean
}

const checkIfEventIsPosterior = (event: any, previousEvent: any) => {
  if (!event || !event.endAt) {
    return false
  }

  if (!previousEvent || !previousEvent.endAt) {
    return true
  }

  const is_posterior = new Date(event.endAt) >= new Date(previousEvent.endAt)
  if (is_posterior) {
    return true
  }

  return false
}

export enum ShowFormStep {
  Category = 'category',
  Info = 'info',
}

const Show = (props: ShowProps) => {
  const { isShop = false } = props

  useDocumentTitle('Show')

  const { t } = useTranslation()
  const history = useHistory()
  const { showId } = useParams<any>()

  const showGlobalId = `Show|${showId}`

  const { data: getShowByIdData, refetch: refetchShow } = useGetShowByIdQuery({
    skip: !showId,
    variables: { nodeId: showGlobalId },
  })

  const show: ShowByIdFragment | undefined =
    getShowByIdData?.node.__typename === 'Show' ? getShowByIdData.node : undefined

  const showGlobalParentCategoryId = show?.category.parentCategory.id
  const featuredShowApplicationStatus = show?.featuredShowApplication?.status

  const { user } = useUser()

  const isLoading = !show || !('id' in show)

  const {
    totalViewers,
    refetchProducts,
    itemsPerPage,
    handleAuctionPageChange,
    handleGiveawayPageChange,
    handleInstantBuyPageChange,
  } = useShow()

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [showFormStep, setShowFormStep] = useState<ShowFormStep>(ShowFormStep.Category)
  const [isProductListExpanded, setIsProductListExpanded] = useState<boolean>(false)
  const [isLaunching, setIsLaunching] = useState<boolean>(false)
  const [isApplyForShowEventDialogOpen, setIsApplyForShowEventDialogOpen] = useState(false)
  const [alreadyReceivedEvents, setAlreadyReceivedEvents] = useState<string[]>([])
  const [alreadyReceivedUuid, setAlreadyReceivedUuid] = useState<string[]>([])

  const [isLaunchingAuction, setIsLaunchingAuction] = useState<boolean>(false) // used to monitor auction launch time
  const [startLaunchingAuction, setStartLaunchingAuction] = useState<Dayjs | undefined>(undefined)

  //monitoring auction launch time
  useEffect(() => {
    let interval: any
    if (isLaunchingAuction) {
      let seconds = 0
      let shouldDisplayMessage = false
      interval = setInterval(() => {
        seconds += 0.5
        if (seconds % 1 === 0) {
          logger({
            level: LOGGER_LEVEL.INFO,
            message: 'still launching auction...',
            meta: { showId: showGlobalId, seconds },
          })
        }
        if (seconds === 3) {
          axiosRetry(axios, {
            retries: 3,
          })

          axios
            .get(VITE_APP_CLIENT_MONITORING_URL)
            .then((res) => {
              if (res.status === 200) {
                logger({
                  level: LOGGER_LEVEL.WARN,
                  message: 'seller can reach the internet but not the backend',
                  meta: { showId: showGlobalId, seconds },
                })
                trackError(new Error('seller can reach the internet but not the backend while launching auction'), {
                  meta: { showId: showGlobalId, seconds },
                })
                shouldDisplayMessage = true
              } else {
                logger({
                  level: LOGGER_LEVEL.INFO,
                  message: 'seller can not reach the internet',
                  meta: { showId: showGlobalId, seconds },
                })
                notificationWarning(t('showLaunchAuctionNetworkError'))
              }
            })
            .catch(() => {
              logger({
                level: LOGGER_LEVEL.INFO,
                message: 'seller can not reach the internet',
                meta: { showId: showGlobalId, seconds },
              })
              notificationWarning(t('showLaunchAuctionNetworkError'))
            })
        } else if (seconds === parseInt(VITE_APP_SHORT_TIMEOUT) / 1000 - 0.5) {
          logger({
            level: LOGGER_LEVEL.WARN,
            message: 'launching auction timed out',
            meta: { showId: showGlobalId, seconds, eventType: 'AUCTION_CREATION_TIMEOUT' },
          })
        } else if (seconds === parseInt(VITE_APP_SHORT_TIMEOUT) / 1000) {
          if (shouldDisplayMessage) {
            notificationWarning(t('showLaunchAuctionNetworkServerError'))
          }
          clearInterval(interval)
          setIsLaunchingAuction(false)
          setIsLaunching(false)
        }
      }, 500)
      return () => clearInterval(interval)
    }
    if (!isLaunchingAuction) {
      clearInterval(interval)
    }
  }, [isLaunchingAuction])

  const [getProductsCount] = useGetCountProductsForSellerLazyQuery()

  const productType = useMemo(
    () => (new URLSearchParams(location.search).get('productType') as TabName) || undefined,
    []
  )
  const { productId } = useParams<any>()
  const [currentTab, setCurrentTab] = useState<TabName>(isShop ? TabName.instantBuy : productType || TabName.auction)
  const [lastCreatedProduct, setLastCreatedProduct] = useState<Product | undefined>()
  const [editedProduct, setEditedProduct] = useState<Product>()

  const [stringProductId, setStringProductId] = useState<string | undefined>()
  const [giveawayAudience, setGiveawayAudience] = useState<ShowGiveawayAudience | null>(null)
  const [productInLaunch, setProductInLaunch] = useState<Product | undefined>(undefined)
  const [isGiveawayModalVisible, setIsGiveawayModalVisible] = useState<boolean>(false)
  const [isFlashSaleModalVisible, setIsFlashSaleModalVisible] = useState<boolean>(false)

  const { startAt, category, terminatedAt, isOffline, endedAt } = show || {}
  const { parentCategory } = category || {}

  const isOfflineShop = isOffline || isShop
  const [startAuction, { data: auctionStartData, loading: isStartAuctionMutationLoading }] = useStartAuctionMutation()
  const [startGiveawayInShow, { data: giveawayStartData, loading: isStartGiveawayInShowMutationLoading }] =
    useStartGiveawayInShowMutation()
  const [startFlashSale, { loading: isStartFlashSaleMutationLoading }] = useStartFlashSaleInShowMutation()

  const redirectToTab = useCallback(
    (createdOrEditedProductType?: ProductType) => {
      const newCurrentTab = createdOrEditedProductType ? productTypeToTabName(createdOrEditedProductType) : currentTab
      const url = `/${isShop ? 'shops' : 'shows'}/${showId}/?productType=${newCurrentTab}`
      history.push(url)
      setCurrentTab(newCurrentTab)
    },
    [currentTab]
  )

  const handleCloseCreateOrEditProduct = useCallback(
    (createdOrEditedProductType?: ProductType) => {
      redirectToTab(createdOrEditedProductType)
      setLastCreatedProduct(undefined)
      setEditedProduct(undefined)
    },
    [isShop, currentTab]
  )

  const handleGoToLastPage = useCallback(
    (type?: ProductType) => {
      getProductsCount({
        variables: {
          showId: showGlobalId,
        },
        onCompleted: (data) => {
          if (data && data.node.__typename === 'Show') {
            if (type === ProductType.Auction) {
              handleAuctionPageChange(Math.ceil(data.node.auctionProductsForSeller.totalCount / itemsPerPage) || 1)
            } else if (type === ProductType.InstantBuy) {
              handleInstantBuyPageChange(
                Math.ceil(data.node.instantBuyProductsForSeller.totalCount / itemsPerPage) || 1
              )
            } else if (type === ProductType.Giveaway) {
              handleGiveawayPageChange(Math.ceil(data.node.giveawayProductsForSeller.totalCount / itemsPerPage) || 1)
            } else {
              handleAuctionPageChange(Math.ceil(data.node.auctionProductsForSeller.totalCount / itemsPerPage) || 1)
              handleInstantBuyPageChange(
                Math.ceil(data.node.instantBuyProductsForSeller.totalCount / itemsPerPage) || 1
              )
              handleGiveawayPageChange(Math.ceil(data.node.giveawayProductsForSeller.totalCount / itemsPerPage) || 1)
            }
          }
        },
      })
    },
    [itemsPerPage, handleAuctionPageChange, handleInstantBuyPageChange, handleGiveawayPageChange]
  )

  const handleProductsImported = async () => {
    await refetchProducts(undefined)
    handleGoToLastPage()
  }

  const handleCreatedOrEditedProduct = useCallback(
    async (product?: Product, options?: ExtraProductFormOptions, type?: ProductType) => {
      const { createAndLaunch, createMore } = options || {}

      if (product) {
        if (createMore) {
          notificationSuccess(`${product.name} successfully created!`)

          setLastCreatedProduct(product)
          const newCurrentTab = product?.type ? productTypeToTabName(product.type) : currentTab
          const url = `/${isShop ? 'shops' : 'shows'}/${showId}/products/new?productType=${newCurrentTab}`
          setCurrentTab(newCurrentTab)
          history.push(url)
        } else {
          handleCloseCreateOrEditProduct(product?.type)
        }

        if (createAndLaunch) {
          launchProduct(product)
        }

        await refetchProducts(product.type)
        handleGoToLastPage(product.type)
      } else {
        handleCloseCreateOrEditProduct(type)
        await refetchProducts()
      }
    },
    [currentTab, productId, refetchProducts]
  )

  // TODO: the following should just update the show state
  const { data } = useCurrentShowBroadcastStatusQuery({
    skip: !showId,
    variables: {
      nodeId: showGlobalId,
    },
  })

  const { data: currentAuctionData, refetch: refetchCurrentAuction } = useCurrentAuctionShowQuery({
    skip: !showId || isOfflineShop,
    variables: { showId: showGlobalId },
    onError: (currentAuctionError) => {
      trackError(currentAuctionError, {
        meta: { showId, scope: 'Show.useCurrentAuctionShowQuery' },
      })
    },
  })

  const { data: currentGiveawayData, updateQuery: updateGiveawayQuery } = useCurrentGiveawayShowQuery({
    skip: !showId || isOfflineShop,
    variables: {
      showId: showGlobalId,
    },
    onError: (currentGiveawayError) => {
      trackError(currentGiveawayError, {
        meta: { showId, scope: 'Show.useCurrentGiveawayShowQuery' },
      })
    },
  })

  const { data: currentRaidData, updateQuery: updateRaidShowQuery } = useCurrentRaidShowQuery({
    skip: !showId || isOfflineShop,
    variables: {
      showId: showGlobalId,
    },
    onError: (currentRaidError) => {
      trackError(currentRaidError, {
        meta: {
          feature: 'show.query.useCurrentRaidShowQuery',
        },
      })
    },
  })

  const {
    data: currentFlashSaleData,
    updateQuery: updateFlashSaleQuery,
    refetch: refetchCurrentFlashSale,
  } = useCurrentFlashSaleQuery({
    skip: !showId || isOfflineShop,
    variables: { showId: showGlobalId },
    onError: (currentFlashSaleError) => {
      trackError(currentFlashSaleError, {
        meta: { showId, scope: 'Show.useCurrentFlashSaleQuery' },
      })
    },
  })

  // TODO: Why not get this from show state instead?
  const isSellerViewer = show?.seller?.id === user?.id || show?.seller.id === user?.id
  const { isBroadcasting = false } = data?.node.__typename === 'Show' ? data?.node : {}
  const isUpcoming = Boolean(startAt && dayjs.utc(startAt).isAfter(dayjs.utc()))
  const showHasStarted = data?.node.__typename === 'Show' && (dayjs.utc().isAfter(dayjs.utc(startAt)) || isBroadcasting)

  const isShowAssumedEnded = Boolean(
    startAt && dayjs.utc().isAfter(dayjs.utc(startAt).add(MAX_EXPECTED_SHOW_DURATION_IN_HOURS, 'hours'))
  )
  const showHasEnded = isShowAssumedEnded && !isBroadcasting

  const isTerminated = Boolean(
    data?.node.__typename === 'Show' && 'terminatedAt' in data?.node ? data?.node.terminatedAt : terminatedAt
  )
  const shouldBeRated = Boolean(show?.id && !show.showSellerRating?.rating && (showHasEnded || isTerminated))

  // Note:
  // Contrary to what it may seem, the following subscriptions automagically work as expected,
  // even if we do not get their results in some way.
  // This is due to the internal caching mechanism of Apollo Client that automatically updates the cache of the related show
  // as soon as it receives a new event from the server, with the show id.
  useCurrentShowBroadcastStartedSubscription({
    skip: !showId,
    variables: { showId: showGlobalId },
    onData: () => {
      logger({
        level: LOGGER_LEVEL.INFO,
        message: 'current show broadcast started received',
        meta: {
          showId: showGlobalId,
        },
      })
    },
  })
  useCurrentShowBroadcastEndedSubscription({
    skip: !showId,
    variables: { showId: showGlobalId },
    onData: () => {
      logger({
        level: LOGGER_LEVEL.INFO,
        message: 'current show broadcast ended received',
        meta: {
          showId: showGlobalId,
        },
      })
    },
  })
  useOnShowCurrentAuctionStatusChangedSubscription({
    skip: !showId || isOfflineShop,
    variables: { showId: showGlobalId },
    onData: ({ data: { data } }) => {
      const auction = JSON.parse(
        atob(
          data?.showCurrentAuctionStatusChanged.show.currentAuction?.id
            ? data?.showCurrentAuctionStatusChanged.show.currentAuction?.id.split('|')[1]
            : ''
        )
      ).auctionId

      const uuid_front = auction + '_' + data?.showCurrentAuctionStatusChanged?.show.currentAuction?.status

      const uuid_back = data?.showCurrentAuctionStatusChanged?.uuid || ''

      if (alreadyReceivedEvents.includes(uuid_front) || alreadyReceivedUuid.includes(uuid_back)) {
        if (alreadyReceivedUuid.includes(uuid_back)) {
          logger({
            level: LOGGER_LEVEL.WARN,
            message: 'current auction received duplicated event with same uuid backend',
            meta: {
              showId: showGlobalId,
              auctionGlobalId: data?.showCurrentAuctionStatusChanged.show.currentAuction?.id,
              auctionId: auction,
              status: data?.showCurrentAuctionStatusChanged.show.currentAuction?.status,
              uuid: uuid_back,
              uuid_front,
            },
          })
        } else {
          logger({
            level: LOGGER_LEVEL.WARN,
            message: 'current auction received duplicated event with different uuid backend',
            meta: {
              showId: showGlobalId,
              auctionGlobalId: data?.showCurrentAuctionStatusChanged.show.currentAuction?.id,
              auctionId: auction,
              status: data?.showCurrentAuctionStatusChanged.show.currentAuction?.status,
              uuid: uuid_back,
              uuid_front,
            },
          })
        }
        return
      } else {
        setAlreadyReceivedEvents([...alreadyReceivedEvents.slice(-49), uuid_front])
        setAlreadyReceivedUuid([...alreadyReceivedUuid.slice(-49), uuid_back])
      }

      if (
        isLaunchingAuction &&
        data?.showCurrentAuctionStatusChanged?.show.currentAuction?.status === ShowAuctionStatus.Countdown
      ) {
        const endDate = dayjs()
        const delayBetweenEvents = startLaunchingAuction ? endDate.diff(startLaunchingAuction) : undefined
        logger({
          level: LOGGER_LEVEL.INFO,
          message: 'current auction status changed received',
          meta: {
            showId: showGlobalId,
            auctionCreationDelayMs: delayBetweenEvents,
            eventType: 'AUCTION_CREATED',
            auctionGlobalId: data?.showCurrentAuctionStatusChanged.show.currentAuction?.id,
            auctionId: auction,
            status: data?.showCurrentAuctionStatusChanged.show.currentAuction?.status,
            uuid: uuid_back,
          },
        })
        setStartLaunchingAuction(undefined)
        notificationSuccess(t('showAuctionLaunched'))
        setIsLaunching(false)
        setIsLaunchingAuction(false)
      } else {
        logger({
          level: LOGGER_LEVEL.INFO,
          message: 'current auction status changed received',
          meta: {
            showId: showGlobalId,
            eventType:
              data?.showCurrentAuctionStatusChanged.show.currentAuction?.status === ShowAuctionStatus.Opened
                ? 'AUCTION_LAUNCHED'
                : undefined,
            auctionGlobalId: data?.showCurrentAuctionStatusChanged.show.currentAuction?.id,
            auctionId: auction,
            status: data?.showCurrentAuctionStatusChanged.show.currentAuction?.status,
            uuid: uuid_back,
          },
        })
      }
    },
  })
  useOnShowCurrentAuctionBidPlacedSubscription({
    skip: !showId || isOfflineShop,
    variables: { showId: showGlobalId },
    onData: ({ data: { data } }) => {
      logger({
        level: LOGGER_LEVEL.INFO,
        message: 'current auction bid placed received',
        meta: {
          showId: showGlobalId,
          data,
          eventType: 'BID_PLACED',
          auctionGlobalId: data?.showCurrentAuctionBidPlaced.show.currentAuction?.id,
        },
      })
    },
  })
  useOnShowCurrentAuctionOrderAddedSubscription({
    skip: !showId || isOfflineShop,
    variables: { showId: showGlobalId },
    onData: ({ data: { data } }) => {
      logger({
        level: LOGGER_LEVEL.INFO,
        message: 'current auction order received',
        meta: { showId: showGlobalId, data },
      })
    },
  })

  useShowCurrentGiveawayUpdateEndSubscription({
    skip: !showId || isOfflineShop,
    variables: { showId: showGlobalId },
    onData: (data) => {
      updateGiveawayQuery((prev) => {
        if (prev?.node.__typename === 'Show' && prev.node.giveaway) {
          if (data.data.data?.showCurrentGiveawayUpdate.__typename === 'ShowGiveawayEnd') {
            const newData = {
              ...prev,
              node: {
                ...prev.node,
                giveaway: {
                  ...prev.node.giveaway,
                  status: ShowGiveawayStatus.Closed,
                  winner: data.data.data.showCurrentGiveawayUpdate.winner
                    ? {
                        username: data.data.data.showCurrentGiveawayUpdate.winner.username,
                        id: data.data.data.showCurrentGiveawayUpdate.winner.id,
                      }
                    : undefined,
                },
              },
            }
            return newData
          }
        }
        return prev
      })
    },
  })

  useOnShowFlashSaleUpdateSubscription({
    skip: !showId || isOfflineShop,
    variables: { showId: showGlobalId },
    onData: (data) => {
      // As the subscription fields are ShowFlashSaleEnd and ShowFlashSaleRemainingQuantityChange, we need to update the query manually and set the updated values in currentFlashSale
      updateFlashSaleQuery((prev) => {
        if (prev?.node.__typename === 'Show' && prev.node.currentFlashSale) {
          if (data.data.data?.showFlashSaleUpdate.__typename === 'ShowFlashSaleEnd') {
            const newData = {
              ...prev,
              node: {
                ...prev.node,
                currentFlashSale: {
                  ...prev.node.currentFlashSale,
                  status: data.data.data?.showFlashSaleUpdate.status,
                },
              },
            }
            return newData
          } else if (data.data.data?.showFlashSaleUpdate.__typename === 'ShowFlashSaleRemainingQuantityChange') {
            const newData = {
              ...prev,
              node: {
                ...prev.node,
                currentFlashSale: {
                  ...prev.node.currentFlashSale,
                  remainingQuantity: data.data.data?.showFlashSaleUpdate.remainingQuantity,
                },
              },
            }
            return newData
          }
        }
        return prev
      })
    },
  })

  // We don't want to listen for transcoding status changes:
  useOnPreShowTeaserVideoTranscodingStatusChangedSubscription({
    skip: !showId || !isUpcoming,
    variables: { showId: showGlobalId },
    onData: (data) => {
      handleVideoUploadTranscodingSubscriptionData(data?.data)
    },
  })

  useOnShowCurrentRaidStatusChangedSubscription({
    skip: !showGlobalId || isOfflineShop,
    variables: {
      showId: showGlobalId,
    },
    onData: (data) => {
      // We do update the query here because we want to update the raid status in the cache and we don't have the show and its id in the cache
      updateRaidShowQuery((prev) => {
        if (prev?.node.__typename === 'Show') {
          return {
            ...prev,
            node: {
              ...prev.node,
              showRaid: data.data.data?.showCurrentRaidStatusChanged?.showRaid,
            },
          }
        }
        return prev
      })
    },
  })

  useOnShowCurrentRaidUserParticipationChangedSubscription({
    skip: !showGlobalId || isOfflineShop,
    variables: {
      showId: showGlobalId,
    },
    onData: (data) => {
      // We do update the query here because we want to update the raid status in the cache and we don't have the show and its id in the cache
      updateRaidShowQuery((prev) => {
        if (prev?.node.__typename === 'Show') {
          if (
            !prev.node.showRaid?.participantsCount ||
            !data.data.data?.showCurrentRaidUserParticipation?.participantsCount
          ) {
            return prev
          }
          return {
            ...prev,
            node: {
              ...prev.node,
              showRaid: {
                ...prev.node.showRaid,
                participantsCount: data.data.data.showCurrentRaidUserParticipation.participantsCount,
              },
            },
          }
        }
        return prev
      })
    },
  })

  const launchAuction = (product: Product) => {
    if (isOfflineShop) {
      notificationDanger(t('shopLaunchError'))
      return
    }

    logger({ level: LOGGER_LEVEL.INFO, message: 'trying to launch auction' })
    const productId = product.id
    setIsLaunching(true)
    setIsLaunchingAuction(true) // used to monitor auction launch time
    const input = { showId: showGlobalId, productId }
    const startDate = dayjs()

    setStartLaunchingAuction(startDate)
    startAuction({
      variables: input,
      context: {
        timeout: VITE_APP_SHORT_TIMEOUT ? parseInt(VITE_APP_SHORT_TIMEOUT) : 5000,
        noRetry: true,
      },
      onCompleted: async (auctionStartData) => {
        const endAtDate = dayjs()
        const delayInMs = endAtDate.diff(startDate)
        const initialDuration = auctionStartData.startAuctionInShow.show.auctionInitialDurationPreset
        if (currentAuction && currentAuction.status === ShowAuctionStatus.Countdown) {
          setIsLaunching(false)
        } else {
          await refetchCurrentAuction()
          setIsLaunching(false)
        }
        setIsLaunchingAuction(false)

        logger({
          level: LOGGER_LEVEL.INFO,
          message: 'launched auction',
          meta: { showId: showGlobalId, productId, startAuctionMutationDelayMs: delayInMs },
        })
        trackEvent('AUCTION_START', {
          props: {
            productId: auctionStartData.startAuctionInShow.auction.product.id,
            initialDuration,
            show: showId,
          },
        })
      },
      onError: (err) => {
        setIsLaunching(false)
        setIsLaunchingAuction(false)

        notificationDanger(err?.message || t('showLaunchAuctionServerError'))

        logger({
          level: LOGGER_LEVEL.ERROR,
          message: 'could not launch auction',
          meta: { showId: showGlobalId, productId, err, eventType: 'AUCTION_CREATION_FAILED' },
        })
        trackError(err, { meta: { ...input, scope: 'Show.launchAuction' } })
      },
    })
  }

  const launchGiveaway = (
    productId: string,
    audience: ShowGiveawayAudience,
    isOpenedToInternationalBuyers: boolean
  ) => {
    if (isOfflineShop) {
      notificationDanger(t('shopLaunchError'))
      return
    }
    setIsLaunching(true)

    const input = {
      showId: showGlobalId,
      productId,
      audience,
      isRestrictedToShowCountry: !isOpenedToInternationalBuyers,
    }

    startGiveawayInShow({
      variables: {
        input,
      },
      context: {
        noRetry: true,
      },
      refetchQueries: [CurrentGiveawayParticipantsDocument],
      update: (cache, { data }) => {
        setIsLaunching(false)
        if (data?.startGiveawayInShow) {
          cache.modify({
            // when we get show form gql instead redux use cache.identify(show)
            id: `Show:${showGlobalId}`,
            fields: {
              currentGiveaway() {
                const newGiveawayRef = cache.writeFragment({
                  data: data.startGiveawayInShow.giveaway,
                  fragment: gql`
                    fragment NewGiveaway on ShowGiveaway {
                      id
                    }
                  `,
                })
                return newGiveawayRef
              },
            },
          })
        }
      },
      onCompleted: (auctionStartData) => {
        setIsLaunching(false)
        trackEvent('GIVEAWAY_START', {
          productId: auctionStartData.startGiveawayInShow.giveaway.product.id,
          giveawayId: auctionStartData.startGiveawayInShow.giveaway.id,
          audience,
          showId,
        })
      },
      onError: (err) => {
        setIsLaunching(false)
        // TODO: We should probably display a custom error message here instead
        notificationDanger(err.message)
        trackError(err, { meta: { ...input, scope: 'Show.launchGiveaway' } })
      },
    })
  }

  const launchFlashSale = (productId: string, discountPercentage: number | null) => {
    if (isOfflineShop) {
      notificationDanger(t('shopLaunchError'))
      return
    }
    setIsLaunching(true)
    const input = { showId: showGlobalId, productId, discountPercentage }
    startFlashSale({
      variables: {
        input,
      },
      context: {
        noRetry: true,
      },
      onCompleted: async (flashSaleStartData) => {
        notificationSuccess(t('showFlashSaleLaunched'))
        const initialDuration = flashSaleStartData.startFlashSaleInShow.flashSale.durationMs
        if (currentFlashSale && currentFlashSale.status === ShowFlashSaleStatus.Opened) {
          setIsLaunching(false)
        } else {
          await refetchCurrentFlashSale()
          setIsLaunching(false)
        }

        trackEvent('FLASH_SALE_START', {
          props: {
            productId: flashSaleStartData.startFlashSaleInShow.flashSale.product.id,
            initialDuration,
            show: showId,
          },
        })
      },
      onError: (err) => {
        setIsLaunching(false)
        notificationDanger(err.message)
        trackError(err, { meta: { ...input, scope: 'Show.launchFlashSale' } })
      },
    })
  }

  const openGiveawayModal = (product: Product) => {
    setStringProductId(product.id)
    setGiveawayAudience(product.giveawayAudience || null)
    setIsGiveawayModalVisible(true)
  }

  const openFlashSaleModal = (product: Product) => {
    setProductInLaunch(product)
    setIsFlashSaleModalVisible(true)
  }

  const launchProduct = useCallback(
    (product: Product) => {
      if (product.type === ProductType.Giveaway) {
        openGiveawayModal(product)
      } else if (product.type === ProductType.InstantBuy) {
        openFlashSaleModal(product)
      } else if (product.type === ProductType.Auction) {
        launchAuction(product)
      }
    },
    [launchAuction]
  )

  // // !!!
  // // We use show?.id below to make sure to send events only for valid shows.
  // // Ex: we don't want to send events if url is '/shows/invalidShowId42'
  // // !!!
  useEffect(() => {
    if (!show?.id) {
      return
    }

    if (isOfflineShop) {
      trackEvent('SHOP_OPEN', { showId })
    } else {
      trackEvent('SHOW_OPEN', { showId: show?.id })
    }
  }, [show?.id])

  // TODO: the following should be replaced by proper a <Link> component
  const goBackToShows = useCallback(() => {
    const url = isOfflineShop ? '/shops' : '/shows'
    history.push(url)
  }, [history, isOfflineShop])

  const toggleProductListExpand = useCallback(() => {
    setIsProductListExpanded(!isProductListExpanded)
  }, [isProductListExpanded])

  const handleEditShowClick = useCallback(
    (showFormStep: ShowFormStep) => {
      trackEvent('SHOW_OPEN_EDIT_SHOW_MODAL', { show: showId })
      setIsModalVisible(true)
      setShowFormStep(showFormStep)
    },
    [showId]
  )

  const chatBoxTabs = useMemo(() => {
    return [
      { title: t('showChatTitle'), content: <Chat />, key: 'chat' },
      {
        title: `${t('showViewersTitle')} ${totalViewers !== null ? `(${totalViewers})` : ''}`,
        content: <ShowViewersList />,
        key: 'viewers',
      },
      { title: t('showAllBannedTitle'), content: <ShowAllBannedViewersList />, key: 'banned' },
    ]
  }, [t, totalViewers])

  const onShowUpdated = () => {
    refetchShow()
  }

  if (!show && !isLoading) return <ErrorPage404 />
  if (!show || !isSellerViewer) return null

  const currentAuction =
    currentAuctionData?.node.__typename === 'Show' && currentAuctionData?.node.auction
      ? currentAuctionData.node.auction
      : null
  const isAuctionRunning =
    currentAuction?.status === ShowAuctionStatus.Opened || currentAuction?.status === ShowAuctionStatus.Countdown
  const currentGiveaway =
    currentGiveawayData?.node.__typename === 'Show' && currentGiveawayData?.node.giveaway
      ? currentGiveawayData.node.giveaway
      : null
  const isGiveawayRunning = currentGiveaway?.status === ShowGiveawayStatus.Opened
  const currentFlashSale =
    currentFlashSaleData?.node.__typename === 'Show' && currentFlashSaleData?.node.currentFlashSale
      ? currentFlashSaleData.node.currentFlashSale
      : null
  const isFlashSalesRunning = currentFlashSale?.status === ShowFlashSaleStatus.Opened

  const auctionIsLast =
    checkIfEventIsPosterior(currentAuction, currentGiveaway) &&
    checkIfEventIsPosterior(currentAuction, currentFlashSale)
  const giveawayIsLast =
    checkIfEventIsPosterior(currentGiveaway, currentAuction) &&
    checkIfEventIsPosterior(currentGiveaway, currentFlashSale)
  const flashSaleIsLast =
    checkIfEventIsPosterior(currentFlashSale, currentAuction) &&
    checkIfEventIsPosterior(currentFlashSale, currentGiveaway)
  const currentRaid =
    currentRaidData?.node.__typename === 'Show' && currentRaidData.node.showRaid ? currentRaidData.node.showRaid : null
  const raidIsRunning = currentRaid?.status === ShowRaidStatus.Opened
  const isShowLaunched = dayjs(startAt).subtract(15, 'minutes').isBefore(dayjs()) || showHasStarted
  const isActivityRunning =
    isAuctionRunning ||
    isGiveawayRunning ||
    isFlashSalesRunning ||
    isStartAuctionMutationLoading ||
    isStartGiveawayInShowMutationLoading ||
    isStartFlashSaleMutationLoading ||
    isLaunching

  const showHasBeenLive = !!isBroadcasting || !!terminatedAt || !!endedAt

  const { name } = show || {}
  const { legacyId: categoryId, name: categoryName, slug: categorySlug } = category || {}

  const hasPendingAuction = Boolean(currentAuction && auctionIsLast)
  const hasPendingGiveaway = Boolean(currentGiveaway && giveawayIsLast)
  const hasPendingRaid = Boolean(raidIsRunning && currentRaid)
  const hasPendingFlashSale = Boolean(currentFlashSale && flashSaleIsLast)

  const shouldDisplayGiveaway = hasPendingGiveaway && !raidIsRunning
  const shouldDisplayFlashSale = hasPendingFlashSale && !raidIsRunning
  const shouldDisplayAuction =
    ((!giveawayIsLast && !auctionIsLast && !flashSaleIsLast) || hasPendingAuction) && !raidIsRunning
  const shouldDisplayRaid = hasPendingRaid

  return (
    <ViewContainer
      id="show"
      isLoading={isLoading}
      leftContent={
        <>
          <Button className="back-action" icon={<LeftOutlined />} label={name} onClick={goBackToShows} />
          {show && <ShowInfos isShowLaunched={showHasBeenLive} show={show} onEdit={handleEditShowClick} />}
        </>
      }
      rightContent={
        <>
          {<ShowSalesFigures showId={showId} />}
          <div className="show-actions">
            {isOfflineShop && <NextItemsOptions isShop={true} isShowLaunched={isShowLaunched} show={show} />}
            {isUpcoming && !isBroadcasting && !isOfflineShop && <QuickStart />}
            {isUpcoming && !isBroadcasting && !isOfflineShop && (
              <ApplyForShowEvent
                featuredShowApplicationStatus={featuredShowApplicationStatus ?? null}
                handleOnClick={() => setIsApplyForShowEventDialogOpen(true)}
              />
            )}
            {!showHasEnded && isBroadcasting && !isOfflineShop && <SendShowNotification showId={showId} />}
            {(showHasEnded || isOfflineShop) && <ExportOrdersButton showId={showId} />}

            <CreateProductTypeSelector
              isShowBroadcasting={isBroadcasting}
              lastCreatedProduct={lastCreatedProduct}
              product={productId === 'new' ? ({ id: 'new' } as Product) : editedProduct}
              showCategory={categorySlug}
              showHasEnded={showHasEnded}
              showId={showId}
              onCancelCreate={handleCloseCreateOrEditProduct}
              onClose={handleCloseCreateOrEditProduct}
              onImported={handleProductsImported}
              onSuccessCreate={handleCreatedOrEditedProduct}
            />
          </div>
        </>
      }
    >
      {isApplyForShowEventDialogOpen && (
        <ApplyForShowEventModal
          isOpen={true}
          setIsOpen={setIsApplyForShowEventDialogOpen}
          showCategorySlug={show.category.slug}
          showGlobalParentCategoryId={showGlobalParentCategoryId ?? ''}
          showId={showGlobalId}
        />
      )}
      <div className="alerts">
        <StripeAccountIssueBanner />
        <WarningInShowBanner />
        <FraudFightingBanner category={parentCategory?.slug} showId={showId} />
        <StripeAutoDebitAgreementBanner />
        {isOfflineShop && <ShopsBanner isTerminated={isTerminated || showHasStarted} shopId={showId} />}
      </div>
      <>
        <section className="auctions-and-products">
          {isShowLaunched && !isOfflineShop && (
            <div className={`auctions`} hidden={isProductListExpanded}>
              {shouldDisplayAuction && (
                <CurrentAuction
                  auctionStartData={auctionStartData}
                  currentAuction={currentAuction}
                  showId={showGlobalId}
                />
              )}
              {shouldDisplayGiveaway && (
                <CurrentGiveaway
                  currentGiveaway={currentGiveaway}
                  giveawayStartData={giveawayStartData}
                  showId={showId}
                />
              )}
              {shouldDisplayFlashSale && <CurrentFlashSale currentFlashSale={currentFlashSale} />}
              {shouldDisplayRaid && <CurrentRaid currentRaidData={currentRaidData} showGlobalId={showGlobalId} />}

              <NextItemsOptions isShowLaunched={isShowLaunched} show={show} />
            </div>
          )}

          <ProductList
            currentTab={currentTab}
            editedProduct={editedProduct}
            giveawayAudience={giveawayAudience}
            handleCloseCreateOrEditProduct={handleCloseCreateOrEditProduct}
            isActivityRunning={isActivityRunning}
            isExpandable={isBroadcasting}
            isFlashSaleModalVisible={isFlashSaleModalVisible}
            isGiveawayModalVisible={isGiveawayModalVisible}
            isProductListExpanded={isProductListExpanded}
            isShop={isOfflineShop}
            isShowBroadcasting={isBroadcasting}
            lastCreatedProduct={lastCreatedProduct}
            launchAuction={launchAuction}
            launchFlashSale={launchFlashSale}
            launchGiveaway={launchGiveaway}
            openFlashSaleModal={openFlashSaleModal}
            openGiveawayModal={openGiveawayModal}
            productInLaunch={productInLaunch}
            sellerId={user?.id}
            setCurrentTab={setCurrentTab}
            setEditedProduct={setEditedProduct}
            setIsFlashSaleModalVisible={setIsFlashSaleModalVisible}
            setIsGiveawayModalVisible={setIsGiveawayModalVisible}
            setLastCreatedProduct={setLastCreatedProduct}
            showCategory={categorySlug}
            showId={showId}
            stringProductId={stringProductId}
            onExpand={toggleProductListExpand}
          />
        </section>

        <div className="metrics-and-chat">
          <ChatBoxProvider isActivityRunning={isActivityRunning} isShowBroadcasting={isBroadcasting} showId={showId}>
            <Card className="chat-management">
              <TabBar
                tabs={chatBoxTabs}
                extraContent={
                  <div className="chat-extra-content">
                    <ChatCommandsAndInfos />
                    <ChatFontSize />
                  </div>
                }
              />
            </Card>
          </ChatBoxProvider>
        </div>

        {/* TODO: use isBroadcasting instead of showHasStarted && !showAsEnded below */}
        <IssueReporter currentAuction={currentAuction} showId={showId} />
        {shouldBeRated && <RateShow showCategory={categoryName} showId={showId} showStartAt={startAt} />}
      </>
      {isModalVisible && (
        <CreateShowModal
          createShop={isOfflineShop}
          isOnlyCategoryAndDate={showFormStep === 'category'}
          providedCategory={showFormStep === 'info' ? categoryId : null}
          providedSlot={showFormStep === 'info' ? moment(startAt) : null}
          setIsModalVisible={setIsModalVisible}
          showId={showId}
          onShowUpdated={onShowUpdated}
        />
      )}
    </ViewContainer>
  )
}

const withContext = (props: ShowProps) => {
  const { showId } = useParams<{ showId: string }>()
  return (
    <ShowProvider showId={showId}>
      <Show {...props} />
    </ShowProvider>
  )
}

export default withContext
