import { isAxiosError } from 'axios'

import Config from '@/config/config'
import { getFromLocalStorage } from '@/helpers/localstorage'
import { getAdditionalHeaders } from '@/network/common/additionalHeaders'
import { store } from '@/reducers'
import { internalRequest } from '@/util/request'
import { trackError } from '@/util/sentry'

import type { RootState } from '@/reducers'

interface LoggerProps {
  level: LOGGER_LEVEL
  message: string
  meta?: { [key: string]: any }
}

type LOGGER_MESSAGE = {
  level: LOGGER_LEVEL
  message: string
  meta: { [key: string]: any }
  timestamp: string
}

export enum LOGGER_LEVEL {
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
}

const MINIMUM_LOGS_LEVEL_DEBUG = [LOGGER_LEVEL.DEBUG, LOGGER_LEVEL.INFO, LOGGER_LEVEL.WARN, LOGGER_LEVEL.ERROR]
const MINIMUM_LOGS_LEVEL_INFO = [LOGGER_LEVEL.INFO, LOGGER_LEVEL.WARN, LOGGER_LEVEL.ERROR]
const MINIMUM_LOGS_LEVEL_WARN = [LOGGER_LEVEL.WARN, LOGGER_LEVEL.ERROR]
const MINIMUM_LOGS_LEVEL_ERROR = [LOGGER_LEVEL.ERROR]

const { VITE_APP_CLIENT_LOGS_LEVEL } = Config

export const logger = async (props: LoggerProps) => {
  const { level, message, meta = {} } = props

  if (VITE_APP_CLIENT_LOGS_LEVEL === undefined) {
    return
  } else if (VITE_APP_CLIENT_LOGS_LEVEL === LOGGER_LEVEL.DEBUG && MINIMUM_LOGS_LEVEL_DEBUG.includes(level)) {
    return formatLogs(level, message, meta)
  } else if (VITE_APP_CLIENT_LOGS_LEVEL === LOGGER_LEVEL.INFO && MINIMUM_LOGS_LEVEL_INFO.includes(level)) {
    return formatLogs(level, message, meta)
  } else if (VITE_APP_CLIENT_LOGS_LEVEL === LOGGER_LEVEL.WARN && MINIMUM_LOGS_LEVEL_WARN.includes(level)) {
    return formatLogs(level, message, meta)
  } else if (VITE_APP_CLIENT_LOGS_LEVEL === LOGGER_LEVEL.ERROR && MINIMUM_LOGS_LEVEL_ERROR.includes(level)) {
    return formatLogs(level, message, meta)
  } else if (
    VITE_APP_CLIENT_LOGS_LEVEL !== LOGGER_LEVEL.DEBUG &&
    VITE_APP_CLIENT_LOGS_LEVEL !== LOGGER_LEVEL.INFO &&
    VITE_APP_CLIENT_LOGS_LEVEL !== LOGGER_LEVEL.WARN &&
    VITE_APP_CLIENT_LOGS_LEVEL !== LOGGER_LEVEL.ERROR
  ) {
    await formatLogs(LOGGER_LEVEL.ERROR, 'Invalid log level', { level: VITE_APP_CLIENT_LOGS_LEVEL })
  } else {
    // No logs sent to server because the level if inferior to the minimum level
    return
  }
}

const formatLogs = async (level: LOGGER_LEVEL, message: string, meta: { [key: string]: any }) => {
  const state = store.getState()
  const {
    authentication: { user },
  } = state as RootState
  const log = [
    {
      level,
      message,
      meta: { userGlobalId: `User|${user?.id}`, userId: user?.id, ...meta },
      timestamp: new Date().toISOString(),
    },
  ]

  await sendLogsToServer(log)
}

window.onbeforeunload = () => {
  const currentPath = location.pathname
  const storedPath = getFromLocalStorage('currentPath')

  //we send the logs to the server before the user leaves the page or refreshes it
  if (currentPath === storedPath) {
    formatLogs(LOGGER_LEVEL.WARN, 'user is reloading the page', {})
  } else {
    formatLogs(LOGGER_LEVEL.INFO, 'user is leaving the page', {})
  }
  return
}

const sendLogsToServer = async (logs: LOGGER_MESSAGE[]) => {
  const state = store.getState()
  const {
    authentication: { token },
  } = state as RootState

  if (logs.length === 0) {
    return
  }
  try {
    const { data } = await internalRequest({
      headers: {
        authorization: token,
        ...(await getAdditionalHeaders()),
      },
      data: { messages: logs },
      url: `/monitoring/client-logs`,
      method: 'POST',
      dispatch: store.dispatch,
    })

    return data
  } catch (error: any) {
    if (isAxiosError(error)) {
      return Promise.reject(error.response?.data)
    }

    trackError(error, { meta: { scope: 'logger' } })
    throw error // Rethrow other errors
  }
}
