import * as Types from '../../network/graphql/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShowMetricsQueryVariables = Types.Exact<{
  showId: Types.Scalars['ID']['input'];
}>;


export type GetShowMetricsQuery = { __typename?: 'Query', node: { __typename?: 'AutoFollowUserCampaign' } | { __typename?: 'AutocompleteSearchItem' } | { __typename?: 'Badge' } | { __typename?: 'Category' } | { __typename?: 'CommercialEvent' } | { __typename?: 'Country' } | { __typename?: 'Emote' } | { __typename?: 'FavoriteProduct' } | { __typename?: 'FavoriteShow' } | { __typename?: 'ImageUpload' } | { __typename?: 'InventoryProduct' } | { __typename?: 'Language' } | { __typename?: 'Order' } | { __typename?: 'OrderedProduct' } | { __typename?: 'ParentCategory' } | { __typename?: 'Product' } | { __typename?: 'ProductForOrdering' } | { __typename?: 'Promotion' } | { __typename?: 'PromotionV2' } | { __typename?: 'RatingFromCustomer' } | { __typename?: 'SellerLedgerPayout' } | { __typename?: 'SellerLedgerTransaction' } | { __typename?: 'SellerShowsAggregatedByMonth' } | { __typename?: 'SellerTier' } | { __typename?: 'Shipment' } | { __typename?: 'Shop' } | { __typename?: 'Show', id: string, totalAmountOrdersSuccessInCurrencyCents: number, totalAmountOrdersNetInCurrencyCents: number, orderedProducts: { __typename?: 'OrderedProductsConnection', totalCount: number } } | { __typename?: 'ShowOrdersGroup' } | { __typename?: 'ShowProductNode' } | { __typename?: 'User' } };

export type GetShowPresentUsersCountQueryVariables = Types.Exact<{
  showId: Types.Scalars['ID']['input'];
}>;


export type GetShowPresentUsersCountQuery = { __typename?: 'Query', node: { __typename?: 'AutoFollowUserCampaign', id: string } | { __typename?: 'AutocompleteSearchItem', id: string } | { __typename?: 'Badge', id: string } | { __typename?: 'Category', id: string } | { __typename?: 'CommercialEvent', id: string } | { __typename?: 'Country', id: string } | { __typename?: 'Emote', id: string } | { __typename?: 'FavoriteProduct', id: string } | { __typename?: 'FavoriteShow', id: string } | { __typename?: 'ImageUpload', id: string } | { __typename?: 'InventoryProduct', id: string } | { __typename?: 'Language', id: string } | { __typename?: 'Order', id: string } | { __typename?: 'OrderedProduct', id: string } | { __typename?: 'ParentCategory', id: string } | { __typename?: 'Product', id: string } | { __typename?: 'ProductForOrdering', id: string } | { __typename?: 'Promotion', id: string } | { __typename?: 'PromotionV2', id: string } | { __typename?: 'RatingFromCustomer', id: string } | { __typename?: 'SellerLedgerPayout', id: string } | { __typename?: 'SellerLedgerTransaction', id: string } | { __typename?: 'SellerShowsAggregatedByMonth', id: string } | { __typename?: 'SellerTier', id: string } | { __typename?: 'Shipment', id: string } | { __typename?: 'Shop', id: string } | { __typename?: 'Show', id: string, presentUsers: { __typename?: 'UserConnection', totalCount: number } } | { __typename?: 'ShowOrdersGroup', id: string } | { __typename?: 'ShowProductNode', id: string } | { __typename?: 'User', id: string } };

export type OnPresentUsersCountChangedSubscriptionVariables = Types.Exact<{
  showId: Types.Scalars['ID']['input'];
}>;


export type OnPresentUsersCountChangedSubscription = { __typename?: 'Subscription', showPresentUsersChanged: { __typename?: 'ShowPresentUsersChangedEvent', show: { __typename?: 'Show', id: string, presentUsers: { __typename?: 'UserConnection', totalCount: number } } } };


export const GetShowMetricsDocument = gql`
    query GetShowMetrics($showId: ID!) {
  node(id: $showId) {
    ... on Show {
      id
      orderedProducts(first: 0) {
        totalCount
      }
      totalAmountOrdersSuccessInCurrencyCents
      totalAmountOrdersNetInCurrencyCents
    }
  }
}
    `;

/**
 * __useGetShowMetricsQuery__
 *
 * To run a query within a React component, call `useGetShowMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowMetricsQuery({
 *   variables: {
 *      showId: // value for 'showId'
 *   },
 * });
 */
export function useGetShowMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetShowMetricsQuery, GetShowMetricsQueryVariables> & ({ variables: GetShowMetricsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShowMetricsQuery, GetShowMetricsQueryVariables>(GetShowMetricsDocument, options);
      }
export function useGetShowMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShowMetricsQuery, GetShowMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShowMetricsQuery, GetShowMetricsQueryVariables>(GetShowMetricsDocument, options);
        }
export function useGetShowMetricsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetShowMetricsQuery, GetShowMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetShowMetricsQuery, GetShowMetricsQueryVariables>(GetShowMetricsDocument, options);
        }
export type GetShowMetricsQueryHookResult = ReturnType<typeof useGetShowMetricsQuery>;
export type GetShowMetricsLazyQueryHookResult = ReturnType<typeof useGetShowMetricsLazyQuery>;
export type GetShowMetricsSuspenseQueryHookResult = ReturnType<typeof useGetShowMetricsSuspenseQuery>;
export type GetShowMetricsQueryResult = Apollo.QueryResult<GetShowMetricsQuery, GetShowMetricsQueryVariables>;
export const GetShowPresentUsersCountDocument = gql`
    query GetShowPresentUsersCount($showId: ID!) {
  node(id: $showId) {
    id
    ... on Show {
      id
      presentUsers(first: 0) {
        totalCount
      }
    }
  }
}
    `;

/**
 * __useGetShowPresentUsersCountQuery__
 *
 * To run a query within a React component, call `useGetShowPresentUsersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowPresentUsersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowPresentUsersCountQuery({
 *   variables: {
 *      showId: // value for 'showId'
 *   },
 * });
 */
export function useGetShowPresentUsersCountQuery(baseOptions: Apollo.QueryHookOptions<GetShowPresentUsersCountQuery, GetShowPresentUsersCountQueryVariables> & ({ variables: GetShowPresentUsersCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShowPresentUsersCountQuery, GetShowPresentUsersCountQueryVariables>(GetShowPresentUsersCountDocument, options);
      }
export function useGetShowPresentUsersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShowPresentUsersCountQuery, GetShowPresentUsersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShowPresentUsersCountQuery, GetShowPresentUsersCountQueryVariables>(GetShowPresentUsersCountDocument, options);
        }
export function useGetShowPresentUsersCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetShowPresentUsersCountQuery, GetShowPresentUsersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetShowPresentUsersCountQuery, GetShowPresentUsersCountQueryVariables>(GetShowPresentUsersCountDocument, options);
        }
export type GetShowPresentUsersCountQueryHookResult = ReturnType<typeof useGetShowPresentUsersCountQuery>;
export type GetShowPresentUsersCountLazyQueryHookResult = ReturnType<typeof useGetShowPresentUsersCountLazyQuery>;
export type GetShowPresentUsersCountSuspenseQueryHookResult = ReturnType<typeof useGetShowPresentUsersCountSuspenseQuery>;
export type GetShowPresentUsersCountQueryResult = Apollo.QueryResult<GetShowPresentUsersCountQuery, GetShowPresentUsersCountQueryVariables>;
export const OnPresentUsersCountChangedDocument = gql`
    subscription OnPresentUsersCountChanged($showId: ID!) {
  showPresentUsersChanged(showId: $showId) {
    show {
      id
      presentUsers(first: 0) {
        totalCount
      }
    }
  }
}
    `;

/**
 * __useOnPresentUsersCountChangedSubscription__
 *
 * To run a query within a React component, call `useOnPresentUsersCountChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPresentUsersCountChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPresentUsersCountChangedSubscription({
 *   variables: {
 *      showId: // value for 'showId'
 *   },
 * });
 */
export function useOnPresentUsersCountChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnPresentUsersCountChangedSubscription, OnPresentUsersCountChangedSubscriptionVariables> & ({ variables: OnPresentUsersCountChangedSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnPresentUsersCountChangedSubscription, OnPresentUsersCountChangedSubscriptionVariables>(OnPresentUsersCountChangedDocument, options);
      }
export type OnPresentUsersCountChangedSubscriptionHookResult = ReturnType<typeof useOnPresentUsersCountChangedSubscription>;
export type OnPresentUsersCountChangedSubscriptionResult = Apollo.SubscriptionResult<OnPresentUsersCountChangedSubscription>;